function createRuleTypeList() {
    return {
        LEVEL: {
            label: "等级规则",
            value: "LEVEL",
            describe: "等级规则"
        },
        KPI: {
            label: "考核规则",
            value: "KPI",
            describe: "考核规则"
        },
        MONEY: {
            label: "价格规则",
            value: "MONEY",
            describe: "价格规则"
        },
        FACTOR: {
            label: "单拎系数规则",
            value: "FACTOR",
            describe: "单拎系数规则"
        },
        DIFFICULTLY: {
            label: "难度系数规则",
            value: "DIFFICULTLY",
            describe: "难度系数规则"
        },
        KPI_SCORE: {
            label: "考核得分系数规则",
            value: "KPI_SCORE",
            describe: "考核得分系数规则"
        },
        NOT_LOCAL: {
            label: "难易程度系数",
            value: 'NOT_LOCAL',
            describe: "难易程度系数"
        },
        /* COEFFICIENT: {
            label: "系数规则",
            value: "COEFFICIENT",
            describe: "系数规则"
        }, */
    }
}

export {
    createRuleTypeList,
}