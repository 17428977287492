var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { style: _vm.backCss, attrs: { spinning: _vm.loading } },
    [
      _c(
        "div",
        { staticClass: "statistics-menu-list" },
        _vm._l(_vm.columns, function (item, index) {
          return _c(
            "a-dropdown",
            {
              key: item.dataIndex,
              ref: "statistics-" + index,
              refInFor: true,
              style: item.isNum ? "" : "display: none;",
              attrs: { trigger: ["click"], placement: "topCenter" },
            },
            [
              _c(
                "div",
                { staticClass: "statistics" },
                [_vm._v("统计"), _c("a-icon", { attrs: { type: "caret-up" } })],
                1
              ),
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  item.isNum
                    ? _c(
                        "a-menu-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.compute(
                                "meanNum",
                                item.dataIndex,
                                index,
                                "求和"
                              )
                            },
                          },
                        },
                        [_vm._v("\n          求和\n        ")]
                      )
                    : _vm._e(),
                  item.isNum
                    ? _c(
                        "a-menu-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.compute(
                                "sumNum",
                                item.dataIndex,
                                index,
                                "平均"
                              )
                            },
                          },
                        },
                        [_vm._v("\n          平均\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "a-dropdown",
        {
          attrs: { placement: "bottomRight", trigger: ["contextmenu"] },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "a-menu",
            { attrs: { slot: "overlay" }, slot: "overlay" },
            _vm._l(_vm.columns, function (item, index) {
              return _c(
                "a-menu-item",
                { key: item.dataIndex },
                [
                  _c(
                    "a-checkbox",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.checkChange(index)
                        },
                      },
                      model: {
                        value: item.checked,
                        callback: function ($$v) {
                          _vm.$set(item, "checked", $$v)
                        },
                        expression: "item.checked",
                      },
                    },
                    [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "vxe-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tableShow,
                  expression: "tableShow",
                },
              ],
              ref: "xTable1",
              staticClass: "mytable-scrollbar",
              attrs: {
                "row-config": {
                  isCurrent: true,
                  isHover: true,
                  height: _vm.height,
                },
                border: "none",
                "row-id": "key",
                "column-config": { resizable: true },
                "footer-method": _vm.footerMethod,
                "filter-config": {
                  remote: true,
                  iconNone: "iconfont icon-shaixuan icon",
                  iconMatch: "iconfont icon-shaixuan icon filter-product-btn",
                },
                "tree-config": _vm.groupBy.length
                  ? {
                      transform: true,
                      lazy: true,
                      hasChild: "hasChild",
                      rowField: "id",
                      loadMethod: _vm.loadChildrenMethod,
                    }
                  : false,
                "sort-config": {
                  multiple: true,
                  orders: ["asc", "desc"],
                  trigger: "cell",
                  sortMethod: _vm.customSortMethod,
                  defaultSort: {
                    field: _vm.copyColumns[0].dataIndex,
                    order: "desc",
                  },
                  iconAsc: "iconfont icon-32pxshengxu icon",
                  iconDesc: "iconfont icon-32pxjiangxu icon",
                },
                data: _vm.dataSource,
                "show-footer": "",
              },
              on: {
                "checkbox-change": _vm.selectChangeEvent,
                "checkbox-all": _vm.selectChangeEvent,
                "filter-visible": _vm.filterVisible,
                "toggle-tree-expand": _vm.toggleExpandChangeEvent,
                "cell-click": _vm.cellClickEvent,
                "filter-change": _vm.filterChangeEvent,
              },
            },
            [
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  type: "checkbox",
                  fixed: "left",
                  width: "80",
                },
              }),
              _vm.groupBy.length
                ? _c("vxe-column", {
                    attrs: {
                      "tree-node": "",
                      fixed: "left",
                      width: "200",
                      field: _vm.groupBy[_vm.openIdx],
                      title: "分组",
                      "show-overflow": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.label
                                        ? row.label +
                                            "（" +
                                            row.groupCount +
                                            "）"
                                        : ""
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2852509068
                    ),
                  })
                : _vm._e(),
              _vm._l(_vm.copyColumns, function (record) {
                return _c("vxe-column", {
                  key: record.dataIndex,
                  attrs: {
                    fixed:
                      record.dataIndex == "nickName" &&
                      _vm.tableType == "userTable"
                        ? "left"
                        : "",
                    field: record.dataIndex,
                    title: record.title,
                    width: record.width,
                    "show-overflow": "",
                    filters:
                      _vm.tableType != "reviewTable" &&
                      (record.dataIndex == "targetType" ||
                        record.dataIndex == "approveStatus" ||
                        record.dataIndex == "departments" ||
                        record.dataIndex == "materialType" ||
                        record.dataIndex == "applyTime" ||
                        record.dataIndex == "materialDepartments" ||
                        record.dataIndex == "positions" ||
                        record.dataIndex == "materialPositions" ||
                        record.dataIndex == "ruleType")
                        ? []
                        : null,
                    sortable: _vm.tableType == "reviewTable" ? true : false,
                    "filter-method": ({ value, row, column }) => {
                      return _vm.filterMethod(
                        record.dataIndex,
                        value,
                        row,
                        column
                      )
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      record.dataIndex == "nickName" &&
                      _vm.tableType == "userTable"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.children && row.children.length
                                  ? [_c("i", { staticClass: "tree-node-icon" })]
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "min-height": "80px",
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "status-img" }, [
                                      row.userFile.avatar
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "35px",
                                              height: "35px",
                                              "border-radius": "50%",
                                            },
                                            attrs: {
                                              src: row.userFile.avatar,
                                              alt: "",
                                            },
                                          })
                                        : _c("img", {
                                            staticStyle: {
                                              width: "35px",
                                              height: "35px",
                                              "border-radius": "50%",
                                            },
                                            attrs: {
                                              src: require("./../../assets/tx.png"),
                                              alt: "",
                                            },
                                          }),
                                      _c("div", {
                                        staticClass: "status",
                                        style:
                                          row.onlineType == 1
                                            ? "background-color:#24B300"
                                            : row.onlineType == 2
                                            ? "background-color:gray"
                                            : "background-color:#ADADAD",
                                      }),
                                    ]),
                                    _c("span", [_vm._v(_vm._s(row.nickName))]),
                                  ]
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "user" &&
                          _vm.tableType == "approveTable"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "status-img" }, [
                                      row.applyUserAvatar
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "35px",
                                              height: "35px",
                                              "border-radius": "50%",
                                            },
                                            attrs: {
                                              src: row.applyUserAvatar,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(row.applyUserName)),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "approveUser" &&
                          _vm.tableType == "approveTable"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "status-img" }, [
                                      row.processUserAvatar
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "35px",
                                              height: "35px",
                                              "border-radius": "50%",
                                            },
                                            attrs: {
                                              src: row.processUserAvatar,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(row.processUserName)),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "nickName"
                        ? {
                            key: "filter",
                            fn: function ({ $panel, column }) {
                              return [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.username,
                                      expression: "username",
                                    },
                                  ],
                                  domProps: { value: _vm.username },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.username = $event.target.value
                                      },
                                      (e) =>
                                        _vm.nickNameFilter(e, $panel, column),
                                    ],
                                  },
                                }),
                              ]
                            },
                          }
                        : record.dataIndex == "platform"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.platform
                                        ? row.platform.platformName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            },
                          }
                        : record.dataIndex == "approveStatus"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  row.status == 1
                                    ? _c(
                                        "span",
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              color: "green",
                                              "margin-right": "5px",
                                            },
                                            attrs: {
                                              type: "check-circle",
                                              theme: "filled",
                                            },
                                          }),
                                          _vm._v("已通过\n            "),
                                        ],
                                        1
                                      )
                                    : row.status == 2
                                    ? _c(
                                        "span",
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              color: "red",
                                              "margin-right": "5px",
                                            },
                                            attrs: {
                                              type: "close-circle",
                                              theme: "filled",
                                            },
                                          }),
                                          _vm._v("未通过\n            "),
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v("等待中")]),
                                ]),
                              ]
                            },
                          }
                        : record.dataIndex == "materialType"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.materialType
                                        ? row.materialType.name
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            },
                          }
                        : record.dataIndex == "platformName"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.children && row.children.length
                                  ? [_c("i", { staticClass: "tree-node-icon" })]
                                  : _vm._e(),
                                _c("span", [_vm._v(_vm._s(row.platformName))]),
                              ]
                            },
                          }
                        : record.dataIndex == "month"
                        ? {
                            key: "filter",
                            fn: function ({ $panel, column }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "padding-left": "10px",
                                      "padding-right": "50px",
                                    },
                                  },
                                  [
                                    _c("a-tree", {
                                      attrs: {
                                        checkable: "",
                                        "tree-data": _vm.treeData,
                                      },
                                      model: {
                                        value: _vm.checkedKeys,
                                        callback: function ($$v) {
                                          _vm.checkedKeys = $$v
                                        },
                                        expression: "checkedKeys",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "productOrder"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [_vm._v(_vm._s(row.productOrder))]),
                              ]
                            },
                          }
                        : record.dataIndex == "productName"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.children && row.children.length
                                  ? [_c("i", { staticClass: "tree-node-icon" })]
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    staticClass: "review-product",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return ((e) => {
                                          _vm.goToProduct(e, row)
                                        }).apply(null, arguments)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.productName))]
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "type"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.type == "TRANSLATOR"
                                        ? "翻译"
                                        : row.type == "BUILDER"
                                        ? "制作"
                                        : row.type == "NOT_LOCAL"
                                        ? "非本地化"
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            },
                          }
                        : record.dataIndex == "creatorName"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [_vm._v(_vm._s(row.creatorName))]),
                              ]
                            },
                          }
                        : record.dataIndex == "value"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "div",
                                  [
                                    !row.editable
                                      ? _c("span", [_vm._v(_vm._s(row.value))])
                                      : !row.groupCount
                                      ? _c("a-input", {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                          model: {
                                            value: row.value,
                                            callback: function ($$v) {
                                              _vm.$set(row, "value", $$v)
                                            },
                                            expression: "row.value",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "departs"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return _vm._l(row.departs, function (one) {
                                return _c("a-tag", { key: one.id }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(one.departName) +
                                      "\n          "
                                  ),
                                ])
                              })
                            },
                          }
                        : record.dataIndex == "positions"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return _vm._l(row.positions, function (one) {
                                return _c("a-tag", { key: one.id }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(one.name || one.positionName) +
                                      "\n          "
                                  ),
                                ])
                              })
                            },
                          }
                        : record.dataIndex == "materialPositions"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return _vm._l(row.positions, function (one) {
                                return _c("a-tag", { key: one.id }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(one.name || one.name) +
                                      "\n          "
                                  ),
                                ])
                              })
                            },
                          }
                        : record.dataIndex == "action"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.goToMaterialList(row)
                                      },
                                    },
                                  },
                                  [_vm._v("查看")]
                                ),
                                _c("defaultSizeUpload", {
                                  attrs: {
                                    btnText: "上传",
                                    upType: "JPG",
                                    materialCategoryId: row.id,
                                    isTable: true,
                                    btnType: "original",
                                    loadData: _vm.loadData,
                                    saveUrl: "/material/edit",
                                  },
                                  on: {
                                    startProgress: () => {},
                                    endProgress: () => {},
                                  },
                                }),
                              ]
                            },
                          }
                        : record.dataIndex == "approveAction"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.status != 1
                                  ? _c(
                                      "a",
                                      {
                                        staticStyle: { "margin-right": "15px" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.approveFinish([row])
                                          },
                                        },
                                      },
                                      [_vm._v("同意")]
                                    )
                                  : _vm._e(),
                                row.status != 2
                                  ? _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.openRemark([row])
                                          },
                                        },
                                      },
                                      [_vm._v("拒绝")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          }
                        : record.dataIndex == "materialDepartments"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return _vm._l(row.departs, function (one) {
                                return _c("a-tag", { key: one.id }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(one.name || one.name) +
                                      "\n          "
                                  ),
                                ])
                              })
                            },
                          }
                        : record.dataIndex == "weekAvailableCount"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [_vm._v(_vm._s(row.weekTotal))]
                            },
                          }
                        : record.dataIndex == "date"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  _vm._s(row.date && row.date.slice(0, 7))
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "userFile.weekFreeCount"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [_vm._v(_vm._s(row.weekCanScheduleCount))]
                            },
                          }
                        : record.dataIndex == "weekTaskCount"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [_vm._v(_vm._s(row.weekTaskCount))]
                            },
                          }
                        : record.dataIndex == "userFile.qq"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  row.userFile.qq
                                    ? _c(
                                        "div",
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              width: "1vw",
                                              color: "hsl(0deg 0% 75%)",
                                            },
                                            attrs: { type: "qq" },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "3px",
                                              },
                                            },
                                            [_vm._v(_vm._s(row.userFile.qq))]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  row.userFile.qqType
                                    ? _c(
                                        "div",
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              width: "1vw",
                                              color: "hsl(0deg 0% 75%)",
                                            },
                                            attrs: { type: "wechat" },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "3px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(row.userFile.qqType)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            },
                          }
                        : record.dataIndex == "userFile.workCity"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getWorkCity(row.userFile.workCity)
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          }
                        : record.dataIndex == "userFile.goodAtProductionType"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "span",
                                  [
                                    row.userFile.goodAtProductionType
                                      ? _vm._l(
                                          row.userFile.goodAtProductionType.split(
                                            ","
                                          ),
                                          function (one) {
                                            return _c("a-tag", { key: one }, [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    one == "其他"
                                                      ? row.userFile
                                                          .skilledProductOther
                                                        ? row.userFile
                                                            .skilledProductOther
                                                        : one
                                                      : one
                                                  ) +
                                                  "\n              "
                                              ),
                                            ])
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "userFile.badAtProductionType"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "span",
                                  [
                                    row.userFile.badAtProductionType
                                      ? _vm._l(
                                          row.userFile.badAtProductionType.split(
                                            ","
                                          ),
                                          function (one) {
                                            return _c("a-tag", { key: one }, [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    one == "其他"
                                                      ? row.userFile
                                                          .unskilledProductOther
                                                        ? row.userFile
                                                            .unskilledProductOther
                                                        : one
                                                      : one
                                                  ) +
                                                  "\n              "
                                              ),
                                            ])
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "targetType"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm.isTaskSelect(row.ruleType) && row.ruleType
                                  ? _c(
                                      "div",
                                      _vm._l(row.stages, function (one) {
                                        return _c("a-tag", { key: one.id }, [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.getStageName(
                                                  one.stageName,
                                                  row.ruleType
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ])
                                      }),
                                      1
                                    )
                                  : row.ruleType == "LEVEL"
                                  ? _c(
                                      "div",
                                      _vm._l(row.members, function (one) {
                                        return _c(
                                          "a-tag",
                                          { key: one.positionId },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  "职位-" + one.positionName
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          }
                        : record.dataIndex == "level"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm.isEdit
                                  ? _c(
                                      "a-select",
                                      {
                                        model: {
                                          value: row.level,
                                          callback: function ($$v) {
                                            _vm.$set(row, "level", $$v)
                                          },
                                          expression: "row.level",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "专家" } },
                                          [_vm._v("专家")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "高级" } },
                                          [_vm._v("高级")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "中级" } },
                                          [_vm._v("中级")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "低级" } },
                                          [_vm._v("低级")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "新人" } },
                                          [_vm._v("新人")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("span", [_vm._v(_vm._s(row.level))]),
                              ]
                            },
                          }
                        : record.dataIndex == "status" &&
                          _vm.tableType != "ratingTable"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.getStatusText(row))
                                  ),
                                ]),
                              ]
                            },
                          }
                        : record.dataIndex == "status" &&
                          _vm.tableType == "ratingTable"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.getRatingStatusText(row)) +
                                    "\n        "
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "ruleType"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.getRuleType(row.ruleType))),
                                ]),
                              ]
                            },
                          }
                        : record.dataIndex == "departments"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return _vm._l(row.departments, function (one) {
                                return _c("a-tag", { key: one.departId }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(one.departName) +
                                      "\n          "
                                  ),
                                ])
                              })
                            },
                          }
                        : record.dataIndex == "userFile.workDays"
                        ? {
                            key: "default",
                            fn: function ({ row }) {
                              return _vm._l(
                                Array.from(row.userFile.workDays || []).sort(
                                  (a, b) => {
                                    return a - b
                                  }
                                ),
                                function (one) {
                                  return _c("a-tag", { key: one }, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          one == "1"
                                            ? "周一"
                                            : one == "2"
                                            ? "周二"
                                            : one == "3"
                                            ? "周三"
                                            : one == "4"
                                            ? "周四"
                                            : one == "5"
                                            ? "周五"
                                            : one == "6"
                                            ? "周六"
                                            : one == "7"
                                            ? "周日"
                                            : one == "-1"
                                            ? "跳过中国法定节假日"
                                            : ""
                                        ) +
                                        "\n          "
                                    ),
                                  ])
                                }
                              )
                            },
                          }
                        : null,
                      record.dataIndex == "targetType"
                        ? {
                            key: "filter",
                            fn: function ({ $panel, column }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "padding-left": "10px",
                                      "padding-right": "50px",
                                      "min-height": "30px",
                                      "max-height": "200px",
                                      "overflow-y": "auto",
                                    },
                                  },
                                  [
                                    _c("a-tree", {
                                      attrs: {
                                        checkable: "",
                                        "tree-data": _vm.treeTargetData,
                                      },
                                      on: {
                                        select: (selectedKeys, info) => {
                                          _vm.treeSelect(
                                            record.dataIndex,
                                            selectedKeys,
                                            info,
                                            $panel
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.checkedTargetKeys,
                                        callback: function ($$v) {
                                          _vm.checkedTargetKeys = $$v
                                        },
                                        expression: "checkedTargetKeys",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "approveStatus"
                        ? {
                            key: "filter",
                            fn: function ({ $panel, column }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "padding-left": "10px",
                                      "padding-right": "50px",
                                      "min-height": "30px",
                                      "max-height": "200px",
                                      "overflow-y": "auto",
                                    },
                                  },
                                  [
                                    _c("a-tree", {
                                      attrs: {
                                        checkable: "",
                                        "tree-data": _vm.approveData,
                                      },
                                      on: {
                                        select: (selectedKeys, info) => {
                                          _vm.treeAppSelect(
                                            record.dataIndex,
                                            selectedKeys,
                                            info,
                                            $panel
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.checkedAppKeys,
                                        callback: function ($$v) {
                                          _vm.checkedAppKeys = $$v
                                        },
                                        expression: "checkedAppKeys",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          }
                        : record.dataIndex == "applyTime"
                        ? {
                            key: "filter",
                            fn: function ({ $panel, column }) {
                              return [
                                _c(
                                  "a-spin",
                                  {
                                    staticStyle: {
                                      "padding-left": "10px",
                                      "padding-right": "50px",
                                      "min-height": "30px",
                                      "max-height": "200px",
                                      "overflow-y": "auto",
                                    },
                                    attrs: { spinning: _vm.filterLoading },
                                  },
                                  [
                                    _c("a-tree", {
                                      attrs: {
                                        defaultExpandedKeys:
                                          _vm.defaultOpenKeys,
                                        checkable: "",
                                        "tree-data": _vm.treeTimeData,
                                      },
                                      on: {
                                        expand: _vm.treeExpand,
                                        select: (selectedKeys, info) => {
                                          _vm.treeSelect(
                                            record.dataIndex,
                                            selectedKeys,
                                            info
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.checkedDeadlineKeys,
                                        callback: function ($$v) {
                                          _vm.checkedDeadlineKeys = $$v
                                        },
                                        expression: "checkedDeadlineKeys",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm.groupBy.length
                ? _c("vxe-column", {
                    attrs: { width: "250" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm.groupBy.length &&
                              row.level == _vm.groupBy.length - 1
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { width: "240px" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _c("a-pagination", {
                                        attrs: {
                                          size: "small",
                                          showLessItems: true,
                                          hideOnSinglePage: true,
                                          pageSize: _vm.tablePage.pageSize,
                                          "default-current": 1,
                                          total: row.groupCount,
                                        },
                                        on: {
                                          change: (page, pageSize) => {
                                            _vm.treeTablePageChange(
                                              page,
                                              pageSize,
                                              row
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1336412818
                    ),
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }