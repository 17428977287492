/**
 * 新增修改完成调用 modalFormOk方法 编辑弹框组件ref定义为modalForm
 * 高级查询按钮调用 superQuery方法  高级查询组件ref定义为superQueryModal
 * data中url定义 list为查询列表  delete为删除单条记录  deleteBatch为批量删除
 */
 import { deleteAction, downFile, getAction, getFileAccessHttpUrl, copyAction, batchCopyAction } from '@/api/manage'
 import store from '@/store'
 import { ACCESS_TOKEN, TENANT_ID } from '@/store/mutation-types'
 import { batchStyleInsertHeader, filterObj } from '@/utils/util'
 import { Modal } from 'ant-design-vue'
 import Vue from 'vue'
 
 export const JeecgListMixin = {
   data() {
     return {
       windowHeight: document.documentElement.clientHeight,
       windowWidth: document.documentElement.clientWidth,
       /* 查询条件-请不要在queryParam中声明非字符串值的属性 */
       queryParam: {},
       /* 数据源 */
       dataSource: [],
       /* 分页参数 */
       ipagination: {
         current: 1,
         pageSize: 10,
         pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
         showTotal: (total, range) => {
           return range[0] + '-' + range[1] + ' 共' + total + '条'
         },
         showQuickJumper: true,
         showSizeChanger: true,
         total: 0
       },
       /* 排序参数 */
       isorter: {
         column: 'createTime',
         order: 'desc'
       },
       /* 筛选参数 */
       filters: {},
       /* table加载状态 */
       loading: false,
       /* table选中keys*/
       selectedRowKeys: [],
       /* table选中records*/
       selectionRows: [],
       /* 查询折叠 */
       toggleSearchStatus: false,
       /* 高级查询条件生效状态 */
       superQueryFlag: false,
       /* 高级查询条件 */
       superQueryParams: '',
       /** 高级查询拼接方式 */
       superQueryMatchType: 'and',
 
       drawerShow: false,
       // drawerShow: true,
       isNewForm: false,
       isEditForm: false,
       isAdd: true,
       curRow: {},
       clickRow: (record, index) => ({
         on: {
           click: () => {
             this.openDetail(record)
           }
         }
       })
     }
   },
   created() {
     if (!this.url) return
 
     this.ipagination.pageSize = this.getPageSizeFromLS(this.url.list)
     if (!this.disableMixinCreated) {
       console.log(' -- mixin created -- ')
       this.loadData()
       //初始化字典配置 在自己页面定义
       this.initDictConfig()
     }
   },
   mounted() {
     /* /// 采用css方案
     window.onresize = () => {
       window.fullHeight = document.documentElement.clientHeight;
       window.fullWidth = document.documentElement.clientWidth;
       this.windowHeight = window.fullHeight;  // 高
       this.windowWidth = window.fullWidth; // 宽
       console.log({ 'this.windowHeight': this.windowHeight });
     }; */
   },
   computed: {
     //token header
     tokenHeader() {
       let head = { 'X-Access-Token': Vue.ls.get(ACCESS_TOKEN) }
       let tenantid = Vue.ls.get(TENANT_ID)
       if (tenantid) {
         head['tenant_id'] = tenantid
       }
       return head
     },
     tableWidth() {
       return this.windowWidth - 376
     },
     tableHeight() {
       return this.windowHeight - 299
     }
   },
   methods: {
     setQueryParsms(params) {},
     initDictConfig() {
       console.log('--这是一个假的方法!')
     },
     openDetail(row) {
       this.isAdd = false
       if (this.mytasksDetialPath) {
         this.$router.push({
           path: `${this.mytasksDetialPath}/${row.taskId}/${row.productionId}/${row.chapterId}?${
             this.fromProduction ? 'fromProduction=1' : ''
           }&record=${encodeURIComponent(
             JSON.stringify({ platformId: row.platformId, productionName: row.productionName })
           )}`
         })
       } else if (this.detailPath) {
         this.$router.push({ path: `${this.detailPath}/${row.id}` })
       } else {
         this.curRow = JSON.parse(JSON.stringify(row)) //防止在drawer中修改内容会影响列表
         console.log({ 'openDetail this.curRow': this.curRow })
         if (this.curRow.cardList) {
           this.curRow.cardList.forEach(item => {
             if (item.dataList == null) {
               item.dataList = [{ valueRequired: 'NO' }]
             }
           })
         }
         this.drawerShow = true
         this.isNewForm = false
       }
     },
     openAddForm() {
       this.isAdd = true
       this.drawerShow = true
       this.isNewForm = true
     },
     drawerClosed(type) {
       this.drawerShow = false
       this.isAdd = true
       if (type) {
         this.loadData()
         if(this.$refs.newTable) this.$refs.newTable.loadData()
       }
     },
 
     handleSuperQuery(params, matchType) {
       //高级查询方法
       if (!params) {
         this.superQueryParams = ''
         this.superQueryFlag = false
       } else {
         this.superQueryFlag = true
         this.superQueryParams = JSON.stringify(params)
         this.superQueryMatchType = matchType
       }
       this.loadData(1)
     },
     getQueryParams() {
       //获取查询条件
       let sqp = {}
       if (this.superQueryParams) {
         sqp['superQueryParams'] = encodeURI(this.superQueryParams)
         sqp['superQueryMatchType'] = this.superQueryMatchType
       }
       var param = Object.assign(sqp, this.queryParam, this.isorter, this.filters)
       param.field = this.getQueryField()
       param.pageNo = this.ipagination.current
       param.pageSize = this.ipagination.pageSize
       return filterObj(param)
     },
     getQueryField() {
       //TODO 字段权限控制
       var str = 'id,'
       this.columns.forEach(function(value) {
         str += ',' + value.dataIndex
       })
       return str
     },
 
     onSelectChange(selectedRowKeys, selectionRows) {
       // console.log(selectedRowKeys,selectionRows,"onSelectChange")
       this.selectedRowKeys = selectedRowKeys
       this.selectionRows = selectionRows
     },
     onClearSelected() {
       this.selectedRowKeys = []
       this.selectionRows = []
     },
     searchQuery() {
       if (this.$refs.newTable) {
         this.$refs.newTable.loadData(1)
       } else {
         this.loadData(1)
       }
     },
     superQuery() {
       this.$refs.superQueryModal.show()
     },
     searchReset() {
       this.queryParam = {}
       this.loadData(1)
     },
     async pSync() {
       console.log('JeecgListMixin batchDel')
       if (!this.url.syncPath) {
         this.$message.error('请设置url.deleteBatch属性!')
         return
       }
       if (this.selectedRowKeys.length <= 0) {
         this.$message.warning('请选择一条记录！')
         return
       } else {
         var ids = this.selectedRowKeys.join(',')
         var that = this
 
         let url = that.url.syncPath
         // 增加作品管理的产品id
         if (this.productionId) {
           url = `${url}/${this.productionId}`
         } else if (this.platformId) {
           url = `${url}/${this.platformId}`
         }
         let titles = this.selectionRows.map(item => {
           return item.title
         })
         console.log(titles)
         this.$confirm({
           title: '确认同步',
           content: '是否同步选中数据?',
           onOk: function() {
             that.loading = true
             copyAction(url, { titles, domain: that.domain ? that.domain : null })
               .then(res => {
                 if (res.success) {
                   that.$message.success(res.message || res.msg ? '同步成功' : '')
                   if (that.$refs.newTable) {
                     that.$refs.newTable.loadData()
                   } else {
                     that.loadData()
                   }
                   that.onClearSelected()
                   that.$emit('loadData')
                 } else {
                   that.$message.warning(res.message || res.msg)
                 }
               })
               .finally(() => {
                 that.loading = false
               })
           }
         })
       }
     },
     batchDel: function() {
       console.log('JeecgListMixin batchDel')
       if (!this.url.deleteBatch) {
         this.$message.error('请设置url.deleteBatch属性!')
         return
       }
       if (this.selectedRowKeys.length <= 0) {
         this.$message.warning('请选择一条记录！')
         return
       } else {
         var ids = this.selectedRowKeys.join(',')
         var that = this
 
         let url = that.url.deleteBatch
         // 增加作品管理的产品id
         if (this.productionId) {
           url = `${url}/${this.productionId}`
         } else if (this.platformId) {
           url = `${url}/${this.platformId}`
         }
 
         this.$confirm({
           title: '确认删除',
           content: '是否删除选中数据?',
           onOk: function() {
             that.loading = true
             deleteAction(url, { ids: ids })
               .then(res => {
                 if (res.success) {
                   that.$message.success(res.message || res.msg ? '删除成功' : '')
                   if (that.$refs.newTable) {
                     that.$refs.newTable.loadData()
                   } else {
                     that.loadData()
                   }
                   that.onClearSelected()
                   that.$emit('loadData')
                 } else {
                   that.$message.warning(res.message || res.msg)
                 }
               })
               .finally(() => {
                 that.loading = false
               })
           }
         })
       }
     },
     batchCopy: function() {
       console.log('JeecgListMixin batchDel')
       if (!this.url.copyBatch) {
         this.$message.error('请设置url.copyBatch属性!')
         return
       }
       if (this.selectedRowKeys.length <= 0) {
         this.$message.warning('请选择一条记录！')
         return
       } else {
         var ids = this.selectedRowKeys
         let url = this.url.copyBatch
         // 增加作品管理的产品id
         if (this.productionId) {
           url = `${url}/${this.productionId}`
         } else if (this.platformId) {
           url = `${url}/${this.platformId}`
         }
         this.$confirm({
           title: '确认创建副本？',
           content: '',
           onOk: () => {
             this.loading = true
             batchCopyAction(url, { ids, domain: this.domain ? this.domain : null })
               .then(res => {
                 if (res.success) {
                   this.$message.success(res.message || '创建成功')
                   if (that.$refs.newTable) {
                     that.$refs.newTable.loadData()
                   } else {
                     that.loadData()
                   }
                   this.onClearSelected()
                   this.$emit('loadData')
                 } else {
                   this.$message.warning(res.message || res.msg)
                 }
               })
               .finally(() => {
                 this.loading = false
               })
           }
         })
       }
     },
     handleDelete: function(id) {
       if (!this.url.delete) {
         this.$message.error('请设置url.delete属性!')
         return
       }
       var that = this
       deleteAction(that.url.delete, { id: id }).then(res => {
         if (res.success) {
           that.$message.success(res.message)
           if (that.$refs.newTable) {
             that.$refs.newTable.loadData()
           } else {
             that.loadData()
           }
         } else {
           that.$message.warning(res.message)
         }
       })
     },
     handleEdit: function(record) {
       this.$refs.modalForm.edit(record)
       this.$refs.modalForm.title = '编辑'
       this.$refs.modalForm.disableSubmit = false
     },
     handleAdd: function() {
       this.$refs.modalForm.add()
       this.$refs.modalForm.title = '新增'
       this.$refs.modalForm.disableSubmit = false
     },
     handleTableChange(pagination, filters, sorter) {
       //分页、排序、筛选变化时触发
       //TODO 筛选
       this.savePageSize2LS(this.url.list, pagination.pageSize)
       if (Object.keys(sorter).length > 0) {
         this.isorter.column = sorter.field
         this.isorter.order = 'ascend' == sorter.order ? 'asc' : 'desc'
       }
       this.ipagination = pagination
       this.loadData()
     },
     savePageSize2LS(url, pageSize) {
       let setting = Vue.ls.get('pagination_setting') || {}
       if (!url) return
       let index = url.path ? url.path.indexOf('?') : url.indexOf('?')
       if (index > 0) {
         url = url.substring(0, index)
       }
       setting[url] = pageSize
       Vue.ls.set('pagination_setting', setting)
     },
     getPageSizeFromLS(url) {
       let setting = Vue.ls.get('pagination_setting') || {}
       if (!url) return
       let index = url.path ? url.path.indexOf('?') : url.indexOf('?')
       if (index > 0) {
         url = url.substring(0, index)
       }
       return setting[url] || 10
     },
     handleToggleSearch() {
       this.toggleSearchStatus = !this.toggleSearchStatus
     },
     // 给popup查询使用(查询区域不支持回填多个字段，限制只返回一个字段)
     getPopupField(fields) {
       return fields.split(',')[0]
     },
     modalFormOk() {
       // 新增/修改 成功时，重载列表
       this.loadData()
       //清空列表选中
       this.onClearSelected()
     },
     handleDetail: function(record) {
       this.$refs.modalForm.edit(record)
       this.$refs.modalForm.title = '详情'
       this.$refs.modalForm.disableSubmit = true
     },
     /* 导出 */
     handleExportXls2() {
       let paramsStr = encodeURI(JSON.stringify(this.getQueryParams()))
       let url = `${window._CONFIG['domianURL']}/${this.url.exportXlsUrl}?paramsStr=${paramsStr}`
       window.location.href = url
     },
     handleExportXls(fileName) {
       if (!fileName || typeof fileName != 'string') {
         fileName = '导出文件'
       }
       let param = this.getQueryParams()
       if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
         param['selections'] = this.selectedRowKeys.join(',')
       }
       console.log('导出参数', param)
       downFile(this.url.exportXlsUrl, param).then(data => {
         if (!data) {
           this.$message.warning('文件下载失败')
           return
         }
         if (typeof window.navigator.msSaveBlob !== 'undefined') {
           window.navigator.msSaveBlob(new Blob([data], { type: 'application/vnd.ms-excel' }), fileName + '.xls')
         } else {
           let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
           let link = document.createElement('a')
           link.style.display = 'none'
           link.href = url
           link.setAttribute('download', fileName + '.xls')
           document.body.appendChild(link)
           link.click()
           document.body.removeChild(link) //下载完成移除元素
           window.URL.revokeObjectURL(url) //释放掉blob对象
         }
       })
     },
     /* 导入 */
     handleImportExcel(info) {
       if (info.file.status !== 'uploading') {
         console.log(info.file, info.fileList)
       }
       if (info.file.status === 'done') {
         if (info.file.response.success) {
           // this.$message.success(`${info.file.name} 文件上传成功`);
           if (info.file.response.code === 201) {
             let {
               message,
               result: { msg, fileUrl, fileName }
             } = info.file.response
             let href = window._CONFIG['domianURL'] + fileUrl
             this.$warning({
               title: message,
               content: (
                 <div>
                   <span>{msg}</span>
                   <br />
                   <span>
                     具体详情请{' '}
                     <a href={href} target="_blank" download={fileName}>
                       点击下载
                     </a>{' '}
                   </span>
                 </div>
               )
             })
           } else {
             this.$message.success(info.file.response.message || `${info.file.name} 文件上传成功`)
           }
           this.loadData()
         } else {
           this.$message.error(`${info.file.name} ${info.file.response.message}.`)
         }
       } else if (info.file.status === 'error') {
         if (info.file.response.status === 500) {
           let data = info.file.response
           const token = Vue.ls.get(ACCESS_TOKEN)
           if (token && data.message.includes('Token失效')) {
             Modal.error({
               title: '登录已过期',
               content: '很抱歉，登录已过期，请重新登录',
               okText: '重新登录',
               mask: false,
               onOk: () => {
                 store.dispatch('Logout').then(() => {
                   Vue.ls.remove(ACCESS_TOKEN)
                   window.location.reload()
                 })
               }
             })
           }
         } else {
           this.$message.error(`文件上传失败: ${info.file.msg} `)
         }
       }
     },
     /* 图片预览 */
     getImgView(text) {
       if (text && text.indexOf(',') > 0) {
         text = text.substring(0, text.indexOf(','))
       }
       return getFileAccessHttpUrl(text)
     },
     /* 文件下载 */
     // update--autor:lvdandan-----date:20200630------for：修改下载文件方法名uploadFile改为downloadFile------
     downloadFile(text) {
       if (!text) {
         this.$message.warning('未知的文件')
         return
       }
       if (text.indexOf(',') > 0) {
         text = text.substring(0, text.indexOf(','))
       }
       let url = getFileAccessHttpUrl(text)
       window.open(url)
     }
   }
 }
 